<template>
  <b-modal
    id="modal-deposit-withdraw"
    cancel-variant="outline-secondary"
    :ok-title="$t('button.save')"
    :cancel-title="$t('button.close')"
    centered
    :title="modalTitle"
    @ok="handleOk"
  >
    <validation-observer
      ref="depositWithdrawForm"
      #default="{ invalid }"
    >
      <!-- form -->
      <n-form-confirmation
        ref="formConfirmation"
        key="depositWithdrawForm"
        :form="$refs.depositWithdrawForm"
        :disabled="invalid"
        @submit="submit"
      >
        <n-input
          v-model="data"
          :fields="formInput"
        />
      </n-form-confirmation>
    </validation-observer>

    <template #modal-ok>
      <b-spinner
        v-if="loading"
        small
        label="Spinning"
      />
      <span v-else>{{ $t("button.save") }}</span>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BSpinner } from 'bootstrap-vue'
import NInput from '@/components/NInput.vue'
import Repository from '@/repositories/RepositoryFactory'
import NFormConfirmation from '@/components/NFormConfirmation.vue'
import { currencyFormat } from '@/libs/helper'
import FormInput from './formInput'
import FormInputPromotion from './formInputPromotion'

const AccountRepository = Repository.get('account')

export default {
  components: {
    BModal,
    BSpinner,
    NFormConfirmation,
    NInput,
  },
  computed: {
    depositPromo() {
      if (this.$store.state.profile.depositPromo) {
        return this.$store.state.profile.depositPromo
      }
      const userData = JSON.parse(localStorage.getItem('userData'))
      return userData?.depositPromo
    },
    formInput() {
      if (this.depositPromo && this.data.type === 'deposit') {
        return [...FormInputPromotion]
      }

      return [...FormInput]
    },
  },
  data() {
    return {
      loading: false,
      data: {
        type: 'deposit',
        amount: 0,
        promoAmount: 0,
        userId: null,
        remark: null,
        username: null,
        ccy: null,
        availableCredit: 0,
      },
      modalTitle: this.$t('field.deposit'),
    }
  },
  watch: {
    'data.type': function (value) {
      this.updateTitle(value)
    },
  },
  methods: {
    updateTitle(value) {
      const username = ` (${this.data.username})`
      const balance = this.data.availableCredit
        ? `${'   -  '
          + 'Available Credit: '}${
          currencyFormat(this.data.availableCredit, this.data.ccy)}`
        : ''
      if (value === 'deposit') {
        this.modalTitle = this.$t('field.deposit') + username + balance
      } else {
        this.modalTitle = this.$t('field.withdraw') + username + balance
      }
    },
    show(modalTitle = null) {
      if (modalTitle) {
        this.modalTitle = modalTitle
      }
      if (
        this.$store.state.profile.avalBalance
        && this.$store.state.profile.accountccy
      ) {
        this.avalBalance = this.$store.state.profile.avalBalance
        this.accountccy = this.$store.state.profile.accountccy
      }
      this.updateTitle(this.data.type)
      this.$bvModal.show('modal-deposit-withdraw')
    },
    hide() {
      this.$bvModal.hide('modal-deposit-withdraw')
    },
    changeData(data) {
      this.data = {
        ...data,
      }
    },
    handleOk(bvModal) {
      bvModal.preventDefault()
      if (this.loading) {
        return
      }

      this.$refs.depositWithdrawForm.validate().then(success => {
        if (success) {
          this.$refs.formConfirmation.confirm()
        }
      })
    },
    submit() {
      this.loading = true
      AccountRepository.depositOrWithdraw(this.data, this.data.type)
        .then(() => {
          this.hide()
          this.$emit('success')
        })
        .catch(error => {
          if (error.response?.status === 422) {
            this.$refs.depositWithdrawForm.setErrors(error.response?.data?.errors)
          }
        })
        .then(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
